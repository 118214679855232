import i18next from 'i18next';
const { SALARY_LIST, TARGET_OCCUPATIONS, SUBAREAS_LIST } = require('./shareConst');

//http://webadmin.jp/toolhash/
const COOKIES = {
  keeped: "43308367",
  visited: "daea0044",
  applyed: "dfb0ce4d",
  called: "e9c49daf",
  COUNT_MAX: 10
}

const LOCALSTORAGES = {
  registerdata: "f194f47f"
}

var LANGUAGE = {
    th : 1,
    english : 2
};

const isEnglish = window.location.host.indexOf('en.') !== -1 || window.location.host.indexOf('img.') !== -1

// 日時をフォーマットする関数
const formatDate = (date) => {
  if (!isEnglish) {
    return date.toLocaleDateString('th-TH', {year: 'numeric', month: 'long', day: 'numeric'});
  } else {
    return date.toLocaleDateString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric'});
  }
}

const TIME_TRANSLATE = {
  toStringDate: (time) => {
    let date = new Date(time)
    return formatDate(date)
  }
}

function getSalaryList() {
  return SALARY_LIST.map((salary) => {
    return {id: salary.id, name: salary.name + i18next.t("jobsindex.search.baht")}
  })
}

function getOccupationName(index) {
  for(let i = 0; i < TARGET_OCCUPATIONS.length; i++) {
    if(TARGET_OCCUPATIONS[i].id == index) {
      return TARGET_OCCUPATIONS[i][isEnglish ? "name": "th_name"]
    }
  }
  return ''
}

function getSalaryName(volume) {
  const salary = getSalaryList()
  for(let i = 0; i < salary.length; i++) {
    if(salary[i].id == volume) {
      return salary[i].name
    }
  }
  return ''
}

function getSubAreaName(index) {
  for(let i = 0; i < SUBAREAS_LIST.length; i++) {
    if(SUBAREAS_LIST[i].id == index) {
      return SUBAREAS_LIST[i][(isEnglish? "name": "th_name")]
    }
  }
  return null
}

const OFFER_CONTROL = {
  isShowLine: (manager) => {
    if(!manager) return false

    if((manager.lineID || manager.lineAddUrl) || (manager.tel && manager.addLineByTel)) {
      return true
    }
  },
  getStrDate: (d) => {
    const date = new Date(d)
    return date.getFullYear() + '-' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1: '0' + (date.getMonth() + 1)) + '-' + (date.getDate() > 9 ? date.getDate(): ('0' + date.getDate()))
  }
}

const isDev = window.location.host.indexOf('mangojob.me') == -1

export {COOKIES, LANGUAGE, TIME_TRANSLATE, LOCALSTORAGES, isEnglish, TARGET_OCCUPATIONS, getSalaryList, getOccupationName, getSalaryName, getSubAreaName, OFFER_CONTROL, isDev}
