import React, { useState, useEffect } from 'react';
import {TextInput, TextArea, DateInput} from "./formComponent.js"
import { useFormContext } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import CircleRadiusBackground from './circle_radius_background.js';

const FormManager = React.memo((props) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if(props.offer){
    //   setValue("title", props.offer.title);
    //   setValue("jobDetail", props.offer.jobDetail);
    //   setValue("salaryTitle", props.offer.salaryTitle);
    //   setValue("salaryDetail", props.offer.salaryDetail);
    //   setValue("workingPeriodTitle", props.offer.workingPeriodTitle);
    //   setValue("workingPeriodDetail", props.offer.workingPeriodDetail);
    //   setValue("startAt", props.offer.startAt);
    }
  }, [props.offer])

    return (
      <section className="mb-8">
        <CircleRadiusBackground>
          <div className="mb-6">
            <h2 className="font-bold mb-1 text-lg">{t("joboffer.manager.title")}</h2>

            <div className="mb-4 text-sm">
                <p>{t("joboffer.manager.text")}</p>
            </div>
          </div>

          <TextInput title={t("joboffer.manager.label.name")} registerName="manager_name" placeholder={t("joboffer.manager.placeholder.name")}  isRequire={true} focus={props.focus} blur={props.blur} />
          
          <TextInput title={t("joboffer.manager.label.tel")} registerName="manager_tel" placeholder={t("joboffer.manager.placeholder.tel")} subtitle={t("joboffer.manager.sublabel.contactaddress")} focus={props.focus} blur={props.blur}/>
          
          <TextInput title={t("joboffer.manager.label.lineid")} registerName="manager_line" placeholder={t("joboffer.manager.placeholder.lineid")} focus={props.focus} blur={props.blur}/>
          
          <TextInput title={t("joboffer.manager.label.linename")} registerName="manager_line_name" placeholder={t("joboffer.manager.placeholder.linename")}  focus={props.focus} blur={props.blur}/>

          <TextInput title={t("joboffer.manager.label.lineaddurl")} registerName="manager_line_url" placeholder={t("joboffer.manager.placeholder.lineaddurl")} subtitle={t("joboffer.manager.sublabel.lineaddurl")} focus={props.focus} blur={props.blur}/>
        </CircleRadiusBackground>

      </section>
    )
})

export default FormManager