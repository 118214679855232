import React from 'react';
import CircleRadiusBackground from './circle_radius_background';
import { LazyImage } from './components/commonComponent';

const FormHeader = React.memo((props) => {  
    return (
        <CircleRadiusBackground isHead={true}>
            <div className="flex flex-col flex-wrap gap-4 justify-between items-start md:flex-row md:flex-nowrap">
                <h1 className="font-bold text-3xl md:my-auto mt-6">{props.title}</h1>
                <div className="w-80 mx-auto md:mx-0 md:ml-auto">
                    <LazyImage src={props.img} />
                </div>
            </div>
        </CircleRadiusBackground>
    )
  })
  
  export default FormHeader