const { isDev, isEnglish } = require('./const')

const API_URL = isDev? 'https://api.local.me:3001': 'https://api.mangojob.me';
const getRequest = (path, query) => {
    var url;
    if(path.match(/^\//)) {
        url = new URL(API_URL + path)
    } else {
        url = new URL(API_URL + '/' + path)
    }

    const params = new URLSearchParams({...query, lang: isEnglish? 'en': 'th'});
    url.search = params.toString();
    return url.toString()
}

const customFetch = async (url, options = {}) => {
    const defaultOptions = {
        mode: 'cors',
        credentials: 'include'
    };

    const mergedOptions = {
        ...defaultOptions,
        ...options,
        headers: {
        ...defaultOptions.headers,
        ...options.headers,
        },
    };

    const response = await fetch(url, mergedOptions);
    return response;
};
  

export {getRequest, customFetch}