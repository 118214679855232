const LocalStorage = {
    getStore: (name) => {
        const value = localStorage.getItem(name)
        try {
            var json = JSON.parse(value)
        } catch(e) {
            json = {}
        }

        return json
    },
    removeStore: (name) => {
        localStorage.removeItem(name)
    },
    addData: (name, data) => {
        var value = localStorage.getItem(name)
        try {
            var json = JSON.parse(value)
        } catch(e) {
            json = {}
        }
        
        if (!json || json === "") {
            json = data
        } else {
            Object.assign(json, data)
        }

        try {
            var jsonStr = JSON.stringify(json)
        } catch(e) {
            localStorage.removeItem(name)
            return
        }

        localStorage.setItem(name, jsonStr)
    }
  }
  
  export {LocalStorage}