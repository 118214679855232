// sitemapで対応するエリア一覧
const SITEMAP_AREAS = [
    {id: 1, name: "bangkok"},
    {id: 2, name: "pattaya"},
    {id: 4, name: "samutprakan"},
    {id: 5, name: "suphanburi"},
    {id: 7, name: "chiangmai"},
    {id: 8, name: "nonthabri"},
    {id: 9, name: "ayutthaya"},
    {id: 10, name: "buriram"},
    {id: 11, name: "rayong"},
    {id: 12, name: "prachuapkhirikhan"},
    {id: 13, name: "korat"},
    {id: 14, name: "anthong"}
]

const SUBAREAS_LIST = [
    {id: 1, name: "Nana", th_name: "นานา"},
    {id: 2, name: "soi cowboy", th_name: "ซอยคาวบอย"},
    {id: 3, name: "Taniya", th_name: "ซอยธนิยะ"},
    {id: 4, name: "walking street", th_name: "วอล์คกิ้งสตรีท"},
    {id: 5, name: "Wang Thonglang", th_name: "วังทองหลาง"},
    {id: 6, name: "Phat Pong", th_name: "พัฒน์พงศ์"},
    {id: 7, name: "Si Racha", th_name: "ศรีราชา"},
    {id: 8, name: "Asoke", th_name: "อโศก"},
    {id: 9, name: "Phetchaburi", th_name: "เพชรบุรี"},
    {id: 10, name: "Thonglor", th_name: "ทองหล่อ"},
    {id: 11, name: "RCA", th_name: "RCA"},
    {id: 12, name: "Min Buri", th_name: "มีนบุรี"},
    {id: 13, name: "Lat Phrao", th_name: "ลาดพร้าว"},
    {id: 14, name: "Muan Samut Prakan", th_name: "อำเภอเมืองสมุทรปราการ "},
    {id: 15, name: "HuayKwang", th_name: "ห้วยขวาง"},
    {id: 16, name: "PhromPhong", th_name: "พร้อมพงษ์"},
    {id: 18, name: "sukhumvit71", th_name: "ถนนสุขุมวิท 71"},
    {id: 19, name: "Soi Buakhao", th_name: "ซอย บัวขาว"},
    {id: 20, name: "Beach road", th_name: "ถนนเลียบชายหาด"},
    {id: 21, name: "Soi Diana", th_name: "ซอยไดอาน่า"},
    {id: 22, name: "Rama9", th_name: "พระราม9"},
    {id: 24, name: "soi LK Metro", th_name: "ซ. แอล เค เมทโทร"},
    {id: 25, name: "soi chaiyapoon", th_name: "ซอยชัยพูน"},
    {id: 26, name: "Pattanakarn", th_name: "พัฒนาการ"},
    {id: 27, name: "Bueng Kum District", th_name: "เขตบึงกุ่ม"},
    {id: 28, name: "pattaya soi", th_name: "พัทยา ซอย"},
    {id: 29, name: "soi Regional Land", th_name: "ซอยสํานักงานที่ดิน"},
    {id: 30, name: "third road", th_name: "ถ.พัทยาสาย3"},
    {id: 32, name: "sukhumvit soi7", th_name: "ซ. สุขุมวิท 7"},
    {id: 33, name: "choku chai", th_name: "โชคชัย"},
    {id: 34, name: "HuaHin", th_name: "หัวหิน"},
    {id: 36, name: "Tree Town", th_name: "ตลาดทรีทาวน์"},
    {id: 37, name: "Mueang Chiang Mai", th_name: "เมืองเชียงใหม่"},
    {id: 38, name: "Nikhom Phatthana", th_name: "นิคมพัฒนา"},
    {id: 39, name: "Mueang Rayong", th_name: "เมืองระยอง"},
    {id: 1, name: "San Sai", th_name: "สันทราย"},
    {id: 1, name: "Sattahip", th_name: "สัตหีบ"},
    {id: 1, name: "SuanLuang", th_name: "สวนหลวง"},
    {id: 1, name: "Muang Anthong", th_name: "เมืองอ่างทอง"}
]

const SALARY_LIST = [
    { id: '10000', name: '10,000+ '},
    { id: '30000', name: '30,000+ '},
    { id: '50000', name: '50,000+ '},
    { id: '100000', name: '100,000+ '}
]

// 追いたい職業が追加されたら要追加対応
// 基本的に追加されないため固定値を設定
const TARGET_OCCUPATIONS = [
    {id: 1, name: "Dancer", th_name: "แดนเซอร์"},
    {id: 4, name: "Coyote", th_name: "โคโยตี้"},
    {id: 5, name: "Waitress", th_name: "สาวเสริฟ"},
    {id: 6, name: "Masseur", th_name: "พนักงานนวด"},
    {id: 7, name: "PR", th_name: "PR"},
    {id: 9, name: "Others", th_name: "งานอื่นๆ"}
  ];

module.exports = {SITEMAP_AREAS, SALARY_LIST, TARGET_OCCUPATIONS, SUBAREAS_LIST};