import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

const CompleteTitle = React.memo((props) => {
  return (
    <div className="mb-4">
      <h2 className="text-center font-bold md:text-xl text-lg">{props.children}</h2>
    </div>
  )
})

const CompleteText = React.memo((props) => {
  return (
    <div className="mb-8 text-sm text-center">
      {props.children}
    </div>
  )
})

const PriorityButton = React.memo((props) => {
  return (
    <div className="mb-8 text-center">
      <Link name="checkButton" className="bg-red-500 rounded-sm px-6 py-2 text-lg text-white w-full max-w-[240px]" to={props.href}>
        {props.children}
      </Link>
    </div>
  )
})

const SecondaryButton = React.memo((props) => {
  return (
    <div className="mb-8 text-center">
      <Link className="bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent roundedw-full max-w-[240px]" to={props.href}>
        {props.children}
      </Link>
    </div>
  )
})

const TemporaryEditComplete = React.memo((props) => {
  const location = useLocation()
  const [offerId, setOfferId] = useState(location.state.id)

  return (
    <main className="bg-gray-100 p-6 py-8 flex-1 overflow-x-hidden flex-grow">
      <CompleteTitle>
        Your JobOffer has been updated.
      </CompleteTitle>

      <PriorityButton href={"/jobs/" + offerId}>
        Check your joboffer updated
      </PriorityButton>
      <SecondaryButton href="/jobs/">
        Return Top
      </SecondaryButton>
    </main>
  )
})

const TemporaryFormComplete = React.memo((props) => {
  const location = useLocation()
  const [offerId, setOfferId] = useState(location.state.id)

  return (
    <main className="bg-gray-100 p-6 py-8 flex-1 overflow-x-hidden flex-grow">
      <CompleteTitle>
        Your JobOffer has been registered.
      </CompleteTitle>
      <CompleteText>
        <p>Thank you for your interest in MANGOJOB.</p>
        <p>Your job offer will be published soon.</p>
      </CompleteText>
      <PriorityButton href={"/jobs/" + offerId}>
        Check your joboffer
      </PriorityButton>
      <SecondaryButton href="/jobs/">
        Return Top
      </SecondaryButton>
    </main>
  )
})

export {TemporaryFormComplete, TemporaryEditComplete}
