function FormError(props) {
  return (
    <p className="mb-1 text-red-500 text-xs whitespace-pre-line">{props.children}</p>
  )
}

function FormTotalError(props) {
  return (
    <>
        {
          props.errors.title && (
          <div className={"bg-red-100 rounded-lg p-4 mb-4 flex flex-row border-red-300 border gap-2"}>
          <div className="pt-0.5">
            <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" viewBox="0 0 24 24" className="w-6 h-6 fill-red-500"><path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm1 14.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5zm0-4a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v5.5z" ></path></svg>
          </div>
          <div className="flex flex-col">
            <div className="font-bold">{props.errors.title}</div>
            <div>
              <ul className="pl-6 list-disc">
                {
                  (props.errors.message.length > 0) && props.errors.message.map((er) => {
                    return (<li key={er}>{er}</li>)
                  }
                  )
                }
              </ul>
            </div>
          </div>
        </div>
    
        )
      }
    </>
  )
}

export {FormError, FormTotalError}