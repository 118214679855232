import React, { useState, useEffect } from 'react';
import {TextInput, TextArea, DateInput, SelectInput} from "./formComponent.js"
import { useFormContext } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import CircleRadiusBackground from './circle_radius_background.js';
import { getRequest } from './common/request.js';

export default function FormPage2(props) {
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const [occupations, setOccupations] = useState([])

  useEffect(() => {
    fetch(getRequest("/occupations"))
    .then(res => res.json())
    .then(json => {
      let current = json.map((value) => {
        value.name = value.title
        return value
      })
      setOccupations(current)
    });
  }, [])

  useEffect(() => {
    if(props.offer){
      setValue("title", props.offer.title);
      setValue("jobDetail", props.offer.jobDetail);
      setValue("salaryTitle", props.offer.salaryTitle);
      setValue("salaryDetail", props.offer.salaryDetail);
      setValue("workingPeriodTitle", props.offer.workingPeriodTitle);
      setValue("workingPeriodDetail", props.offer.workingPeriodDetail);
      setValue("startAt", props.offer.startAt);
    }
  }, [props.offer])

    return (
      <section className="mb-8">

        <CircleRadiusBackground>
          <TextInput title={t("joboffer.label.title")} registerName="title" placeholder={t("joboffer.placeholder.title")} isRequire={true} subtitle={t("joboffer.sublabel.title")} focus={props.focus} blur={props.blur}/>

          <SelectInput title={t("joboffer.label.occupation")} registerName="jobOccupationId" isRequire={true} placeholder={t("joboffer.placeholder.occupation")} selects={occupations} subtitle={t("joboffer.sublabel.occupation")} focus={props.focus} blur={props.blur} defaultValue={props.storedData? props.storedData.jobOccupationId: ""}/>

          <TextArea title={t("joboffer.label.detail")} registerName="jobDetail" placeholder={t("joboffer.placeholder.detail")} isRequire={true} subtitle={t("joboffer.sublabel.detail")} focus={props.focus} blur={props.blur}/>
        </CircleRadiusBackground>

        <CircleRadiusBackground>
          <div className="mb-6">
            <h2 className="font-bold mb-1 text-lg">{t("joboffer.subtitle.salary")}</h2>
          </div>
          <TextInput title={t("joboffer.label.salary")} registerName="salaryTitle" placeholder={t("joboffer.placeholder.salary")} isRequire={true} subtitle={t("joboffer.sublabel.salary")} focus={props.focus} blur={props.blur}/>

          <TextArea title={t("joboffer.label.salarydetail")} registerName="salaryDetail" placeholder={t("joboffer.placeholder.salarydetail")} isRequire={true} subtitle={t("joboffer.sublabel.salarydetail")} focus={props.focus} blur={props.blur}/>
        </CircleRadiusBackground>

        <CircleRadiusBackground>
          <div className="mb-6">
            <h2 className="font-bold mb-1 text-lg">{t("joboffer.subtitle.working")}</h2>
          </div>
          <TextInput title={t("joboffer.label.workingperiod")} registerName="workingPeriodTitle" placeholder={t("joboffer.placeholder.workingperiod")} isRequire={true} subtitle={t("joboffer.sublabel.workingperiod")} focus={props.focus} blur={props.blur}/>
          <TextArea title={t("joboffer.label.workingperioddetail")} registerName="workingPeriodDetail" placeholder={t("joboffer.placeholder.workingperioddetail")} isRequire={true} subtitle={t("joboffer.sublabel.workingperioddetail")} focus={props.focus} blur={props.blur}/>
        </CircleRadiusBackground>

      </section>
    )
}
