import React, { Children } from 'react';

const CircleRadiusBackground = React.memo((props) => {  
    return (
      <div className={"bg-white rounded-lg " + (props.isHead? "px-4 mb-4": "p-4 mb-4")}>
        {props.children}
      </div>
    )
  })
  
  export default CircleRadiusBackground