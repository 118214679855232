import React, { useState, createRef, useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stepper } from 'react-form-stepper';
import { CSSTransition } from 'react-transition-group'
import { Link } from 'react-router-dom';

import { FormTotalError} from "./formerror.js"
import FormPage1 from "./form_page1.js"
import FormPage2 from "./form_page2.js"
import FormPage3 from "./form_page3.js"
import FormManager from './form_manager.js';
import FormHeader from './form_header.js';
import CircleRadiusBackground from './circle_radius_background.js';
import {Analytics} from './common/analytics.js' 
import { IndicatorButton, Lines, LazyImage } from './components/commonComponent.js';
import { LocalStorage } from './common/localstorage.js';
import { LOCALSTORAGES } from './common/const.js';
import { Overlay } from './components/overlay.js';

import { getRequest } from './common/request.js';

const OverlayContent = React.memo(() => {
  const { t } = useTranslation();
  return (
  <>
    <div className="container mx-auto flex flex-col px-6">
      <h2 className="py-4 text-3xl font-bold text-center">{t("jobform.overlay.title")}</h2>
      <div className="divide-y divide-gray-700">
        {/* https://mambaui.com/components/steps
        <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
          <div className="flex items-center justify-center lg:col-span-1 col-span-full">
            <LazyImage src="https://d34k7i5akwhqbd.cloudfront.net/allspark/static/images/ja_jp-q32022-step-2-c29423.png" alt="求人情報を入力する" />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="w-16 h-16">
              <path d="M472,16H168a24,24,0,0,0-24,24V344a24,24,0,0,0,24,24H472a24,24,0,0,0,24-24V40A24,24,0,0,0,472,16Zm-8,320H176V48H464Z"></path>
              <path d="M112,400V80H80V408a24,24,0,0,0,24,24H432V400Z"></path>
              <path d="M48,464V144H16V472a24,24,0,0,0,24,24H368V464Z"></path>
            </svg> 
          </div>
          <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
            <span className="text-xl font-bold md:text-2xl">求人情報を入力するだけ</span>
            <span className="mt-4 text-gray-300"> 画面の案内に従って求人の基本情報を入力してください。入力した情報は一時保存されるため、途中から入力を再開することができます。 </span>
          </div>
        </div> */}
        <div className="grid lg:gap-x-3 justify-center grid-cols-4 px-3 py-8 mx-auto space-y-8 lg:space-y-0">
          <div className="flex items-center justify-center lg:col-span-1 col-span-full">
            <LazyImage src="/img/owner/overlay.png" className="w-9/12 lg:w-full" />
          </div>
          <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
            <span className="text-xl font-bold md:text-2xl">{t("jobform.overlay.subtitle")}</span>
            <span className="mt-4 text-gray-400">
              <Lines words={t("jobform.overlay.description")} />
            </span>
            <span className="mt-3 text-xs underline underline-offset-2 text-line"><Link to="https://lin.ee/wVh1wgH">{t("jobform.overlay.line")}</Link></span>
          </div>
        </div>
      </div>
    </div>
  </>
  )
})

export default function Form() {
  // const { register, watch, getValues, FormProvider, handleSubmit, formState: {errors} } = useForm({mode: "onTouched", shouldFocusError: false});
  // const watchArea = watch("areaId", "");
  const methods = useForm({mode: "onTouched", shouldFocusError: false})
  const [files, setFiles] = useState([])
  const [photos, setPhotos] = useState([])
  const [errorMessage, seterrorMessage] = useState({})
  const { t } = useTranslation();
  const [index, setIndex] = useState(0)

  const navigate = useNavigate();

  const requiredContentArray = [
    [
      {name: "name", label: t("jobform.label.storename")}, 
      {name: "areaId", label: t("jobform.label.area")}, 
      {name: "subAreaId", label: t("jobform.label.subarea")}, 
      {name: "typeId", label: t("jobform.label.business")}
    ],
    [
      {name: "title", label: t("joboffer.label.title")}, 
      {name: "jobDetail", label: t("joboffer.label.detail")}, 
      {name: "jobOccupationId", label: t("joboffer.label.occupation")}, 
      {name: "salaryTitle", label: t("joboffer.label.salary")}, 
      {name: "salaryDetail", label: t("joboffer.label.salarydetail")}, 
      {name: "workingPeriodTitle", label: t("joboffer.label.workingperiod")}, 
      {name: "workingPeriodDetail", label: t("joboffer.label.workingperioddetail")}
    ],
    [
      {name: "manager_name", label: t("joboffer.manager.label.name")},
      {name: ["manager_line", "manager_tel"], label: t("joboffer.manager.label.lineid") + " or " + t("joboffer.manager.label.tel")},
    ]
  ]

  const isArray = (obj) => {
      if((typeof obj)=="object"){
          if(obj.length!=undefined) return true;
          else{for(t in obj){
              return false
          }}
      }else return false
  }

  const checkRequired = () => {
    var message = [];
    
    requiredContentArray[index].forEach(element => {
      if(isArray(element.name)) {
        var isExist = false
        element.name.forEach(name => {
          const value = methods.getValues(name)
          if(value && value != "") {
            isExist = true
          }
        }) 

        if(!isExist) {
          message.push(element.label)
        }
      }
      else {
        const value = methods.getValues(element.name) 
        if(!value || value == "") {
          message.push(element.label)
        }
      }
    })

    return message
  }

  useEffect(() => {
    restoreData()
  }, [])

  const onSubmit = data => {
    setLoading(true)
    Analytics.sendEventWithLocation(`tapSubmit`)
    //データ登録で転ける時もあるため最後のサブミットの前でも保存
    saveEntry()
    const message = checkRequired()
    if(message.length > 0) {
      Analytics.sendEventWithLocation(`lackofInputAtSubmit`, {source: message.join()})
      seterrorMessage({title: t("jobform.errorMessage.pleaseinput"), message: message});
      setLoading(false)
      return
    }
    seterrorMessage({});
    Analytics.sendEventWithLocation(`tapSubmitCanGo`)

    var formData = new FormData();
    for(let i = 0; i < files.length; i++){
      formData.append("files", files[i].raw)
    }
    delete data.files

    if(photos[0]){
      formData.append("photo", photos[0].raw)
      delete data.photo
    }

    formData.append('json', JSON.stringify(data));

    const options = {
      method: 'POST',
      body: formData
    };

    fetch(getRequest('/temporary'), options).then(function(response) {
      setLoading(false)

      if (response.status == 200) {
        response.json()
        .then((result) => {
          //　データ登録が成功したことを契機に途中保存していたデータのローカルストレージからの削除
          LocalStorage.removeStore(LOCALSTORAGES.registerdata)
          navigate('/completeRegister', {state: {id: result.id}})
        })
      }
      else {
        seterrorMessage({title: t("jobform.errorMessage.serverError")})
      }
    })
    .catch(error => {
      setLoading(false)
      seterrorMessage({title: "Please check your network"})
    });
  }

  const titleList = [
    {title: t("jobform.title.form1"), img: "/img/owner/store.jpg"},
    {title: t("jobform.title.form2"), img: "/img/owner/girls.jpg"},
    {title: t("jobform.title.form3"), img: "/img/owner/additionalinfo.jpg"},
  ]

  const classNames={
    enter: 'opacity-0',
    enterActive: 'transition-opacity opacity-100 duration-1000 ease-linear',
    exit: 'opacity-100',
    exitActive: 'transition-opacity opacity-0 duration-1000 ease-linear absolute top-0 left-0 -z-10',
    exitDone: 'opacity-0 hidden fixed top-0 left-0',
   }

  const incrementIndex = (prev) => { setIndex((prev) => prev + 1 )}
  const decrementIndex = (prev) => { setIndex((prev) => prev - 1 )}
  var [loading, setLoading] = useState(false)

  var [values, setValues] = useState({})

  const saveEntry = () => {
    LocalStorage.addData(LOCALSTORAGES.registerdata, methods.getValues())
  }

  const restoreData = () => { 
    var values = LocalStorage.getStore(LOCALSTORAGES.registerdata)

    if(values) {
      Object.keys(values).forEach((key) => {
        // テキスト形のデータであればそのままsetValueできる
        methods.setValue(key, values[key])
      })
  
      setValues(values);
    }
  }

  const tapNext = () => {
    Analytics.sendEventWithLocation(`tapNextForm`, {page: index})
    saveEntry()
    if(isEnableNext()) {
      window.scrollTo({ top: 64 });
      incrementIndex(index)
      Analytics.sendEventWithLocation(`tapNextCanGo`, {page: index})  
    }
  }
  const tapBefore = () => {
    Analytics.sendEventWithLocation(`tapBeforeForm`, {page: index})
    saveEntry()
    seterrorMessage({})
    window.scrollTo({ top: 64 });
    decrementIndex(index)
  }

  const isEnableNext = () => {
    const message = checkRequired()
    seterrorMessage({})

    if(message.length > 0) {
      Analytics.sendEventWithLocation(`lackofInput`, {source: message.join()})
      seterrorMessage({title: t("jobform.errorMessage.pleaseinput"), message: message})
      return false
    } else {
      return true;
    }
  }

  const focusInput = (e) => {
    Analytics.sendEventWithLocation(`focus_${e.target.name}`)
  }
  const unFocusInput = (e) => {
    const value = methods.getValues(e.target.name)
    Analytics.sendEventWithLocation(`blur_${e.target.name}`, {length: value})
  }

  const isFinal = () => { return titleList.length === index + 1 ? true: false }

const nodeRef = createRef(null)
const nodeRef2 = createRef(null)
const nodeRef3 = createRef(null)
const refs = [createRef(null), createRef(null), createRef(null)]

  return (
    <main className="flex-1 overflow-x-hidden flex-grow">
      <Overlay><OverlayContent/></Overlay>
      <Stepper
        steps={[{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }]}
        activeStep={index}
      />
      
      <section className="bg-gray-100 md:p-6 py-8 p-2">

        <div className="relative">
          {titleList.map((element, i) => (
            <CSSTransition key={i} unmountOnExit={true} in={index === i} timeout={1500} nodeRef={refs[i]} classNames={classNames}>
              <div ref={refs[i]}>
                <FormHeader title={element.title} img={element.img}/>
              </div>
            </CSSTransition>
          ))}
        </div>

      <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="relative">
            <CSSTransition in={index === 0} nodeRef={nodeRef} classNames={classNames} unmountOnExit={false} timeout={1500}>
              <div ref={nodeRef} className={(index !== 0 ? 'hidden' : '')}>
                <FormPage1 setImages={setFiles} images={files} focus={focusInput} blur={unFocusInput} storedData={values}/>
                <FormTotalError errors={errorMessage}/>
                <CircleRadiusBackground>
                  <div className="flex flex-col gap-3 md:justify-end md:flex-row">
                    <button type="button" className="bg-red-500 rounded-lg px-6 py-2 text-lg text-white ms:w-full md:w-fit font-bold" onClick={tapNext}>{t("jobform.continue")}</button>
                  </div>
                </CircleRadiusBackground>
              </div>
            </CSSTransition>
            <CSSTransition in={index === 1} nodeRef={nodeRef2} classNames={classNames} unmountOnExit={false} timeout={1500}>
              <div ref={nodeRef2} className={(index !== 1 ? 'hidden' : '')}>
                <FormPage2 focus={focusInput} blur={unFocusInput} storedData={values}/>
                <FormTotalError errors={errorMessage}/>
                <CircleRadiusBackground>
                  <div className="flex flex-col md:flex-row-reverse md:place-content-between gap-3">
                    <button type="button" className="bg-red-500 rounded-lg px-6 py-2 text-lg text-white font-bold ms:w-full" onClick={tapNext}>{t("jobform.continue")}</button>
                    <button type="button" className="flex items-center gap-2 flex-row bg-white rounded-lg px-6 py-2 text-lg text-red-500 font-bold border-red-500 border ms:w-full justify-center" onClick={tapBefore}>
                      <LazyImage src="/img/angle-left.svg" className="w-4 h-4"/>
                      {t("jobform.back")}
                    </button>
                  </div>
                </CircleRadiusBackground>
              </div>
            </CSSTransition>
            <CSSTransition in={index === 2} nodeRef={nodeRef3} classNames={classNames}  timeout={1500}>
              <div ref={nodeRef3} className={(index !== 2 ? 'hidden' : '')}>
                <FormManager focus={focusInput} blur={unFocusInput}/>
                <FormPage3 setImages={setPhotos} images={photos} focus={focusInput} blur={unFocusInput} storedData={values}/>

                <FormTotalError errors={errorMessage}/>

                <CircleRadiusBackground>
                  <div className="flex flex-col md:flex-row-reverse md:place-content-between gap-3">
                    <IndicatorButton loading={loading} disabled={loading} className="bg-red-500 rounded-lg px-6 py-2 text-lg text-white font-bold ms:w-full">{t("jobform.submit")} </IndicatorButton>
                    <button type="button" className="flex items-center gap-2 flex-row bg-white rounded-lg px-6 py-2 text-lg text-red-500 font-bold border-red-500 border ms:w-full justify-center" onClick={tapBefore}>
                      <LazyImage src="/img/angle-left.svg" className="w-4 h-4"/>
                      {t("jobform.back")}
                    </button>
                  </div>
                </CircleRadiusBackground>
              </div>
            </CSSTransition>
          </div>
      </form>
      </FormProvider>

      <CircleRadiusBackground>
        <div className='flex flex-col gap-3'>
        <h2 className="font-bold mb-1 text-lg">{t("jobform.contactline")}</h2>
          <div className="w-full"><LazyImage src="https://qr-official.line.me/gs/M_956zurjw_GW.png" className="w-28 mx-auto"/></div>
          <div className="w-full underline underline-offset-2 text-line text-center"><Link to="https://lin.ee/wVh1wgH">https://lin.ee/wVh1wgH</Link></div>
        </div>
      </CircleRadiusBackground>
      </section>
    </main>
  );
}
