import React, { useEffect, Suspense } from 'react'; //Reactを読み込んでいる
import ReactDOM from 'react-dom/client';
//画面遷移で使用する{ BrowserRouter, Route, Switch }を'react-router-dom'から読み込んでいる
//import { BrowserRouter, Route } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { CookiesProvider } from "react-cookie"

import Form from "./form.js"
import {JobList} from "./joblist.js"
import EditForm from "./edit_form.js"
import {TemporaryFormComplete, TemporaryEditComplete} from "./completeComponent.js"

import "./css/output.css"
import "./index.css"
import {COOKIES, isEnglish} from './common/const.js'
import {Head, HeadWithOgp, OwnerHeadWithOgp} from "./components/head.js"
import "./i18n/config.js";
import i18next from 'i18next';
import i18n from "i18next";
import CommonPageLayout from './page/layout/commonPageLayout';

const root = ReactDOM.createRoot(document.getElementById('root'));
const JobsIndex = React.lazy(() => import(/* webpackPrefetch: true */ './jobsIndex.js'));
const ContactComplete = React.lazy(() => import('./contactComplete.js'));
const ApplyComplete = React.lazy(() => import('./applyComplete.js'));
const NotFound = React.lazy(() => import('./NotFound.js'));
const Terms = React.lazy(() => import('./terms.js'));
const PrivacyPolicy = React.lazy(() => import('./privacypolicy.js'));
const Contact = React.lazy(() => import('./contact.js'));
const Apply = React.lazy(() => import('./apply.js'));
const SitemapTop = React.lazy(() => import('./page/sitemaptop.js'));
const SitemapAreaTop = React.lazy(() => import('./page/sitemapareatop.js'));
const About = React.lazy(() => import('./page/about.js'));
const Keep = React.lazy(() => import('./keep.js'));
const TopPage = React.lazy(() => import(/* webpackPrefetch: true */ './topPage.js'));
const FullJobCard = React.lazy(() => import(/* webpackPrefetch: true */ './top.js'));

const LanguageCheck = React.memo((props) => {
  //ドメインを見て言語切り替え
  useEffect(() => {
    if(isEnglish) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("th");
    }
  }, [])
  return (<>{props.children}</>)
})

const Analytics = React.memo(() => {
  return(
    <>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-5DFC6PTVZ3"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-5DFC6PTVZ3');
          `
        }}
      />
    </>
  )
})

const LazyLoadWrapper = React.memo(({ children, fallback = <div className='min-h-screen bg-white'></div> }) => {
  return (
    <Suspense fallback={fallback}>
      {children}
    </Suspense>
  );
})

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  return null;
}

root.render(
  <>
    <HelmetProvider>
      <CookiesProvider>
        <LanguageCheck>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<CommonPageLayout />}>
                <Route index element={<><HeadWithOgp /><LazyLoadWrapper><TopPage /></LazyLoadWrapper></>} />
                <Route path="/form" element={<><OwnerHeadWithOgp/><Form /></>} />
                <Route path="completeRegister" element={<><Head/><TemporaryFormComplete /></>} />
                <Route path="completeEdit" element={<><Head/><TemporaryEditComplete /></>} />
                <Route path="jobs" element={<><JobList /></>} />
                <Route path="jobs/:jobId" element={<><LazyLoadWrapper><FullJobCard /></LazyLoadWrapper></>} />
                <Route path="jobs/:jobId/edit" element={<><EditForm /></>} />

                <Route path=":area/jobslist" element={<LazyLoadWrapper><JobsIndex /></LazyLoadWrapper>} />
                <Route path="jobslist" element={<LazyLoadWrapper><JobsIndex /></LazyLoadWrapper>} />
                <Route path="apply/:jobId" element={<><HeadWithOgp noindex={true} pretitle={i18next.t("head.pretitle.applyjobid")} /><LazyLoadWrapper><Apply /></LazyLoadWrapper></>} />
                <Route path="applyComplete" element={<><HeadWithOgp noindex={true} pretitle={i18next.t("head.pretitle.applycomplete")} /><LazyLoadWrapper><ApplyComplete /></LazyLoadWrapper></>} />
                <Route path="contact" element={<><HeadWithOgp pretitle={i18next.t("head.pretitle.contact")} /><LazyLoadWrapper><Contact /></LazyLoadWrapper></>} />
                <Route path="contactComplete" element={<><HeadWithOgp noindex={true} pretitle={i18next.t("head.pretitle.contactcomplete")} /><LazyLoadWrapper><ContactComplete /></LazyLoadWrapper></>} />
                <Route path="privacypolicy" element={<><HeadWithOgp pretitle={i18next.t("head.pretitle.privacypolicy")} /><LazyLoadWrapper><PrivacyPolicy /></LazyLoadWrapper></>} />
                <Route path="terms" element={<><HeadWithOgp pretitle={i18next.t("head.pretitle.termscondition")} /><LazyLoadWrapper><Terms /></LazyLoadWrapper></>} />
                <Route path="about" element={<><HeadWithOgp pretitle={i18next.t("head.pretitle.about")} /><LazyLoadWrapper><About /></LazyLoadWrapper></>} />
                <Route path="keepList" element={<><HeadWithOgp noindex={true} pretitle={i18next.t("head.pretitle.keeplist")} /><Keep isKeepPage={true} cookieName={COOKIES.keeped}/></>} />
                <Route path="viewList" element={<><HeadWithOgp noindex={true} pretitle={i18next.t("head.pretitle.viewlist")} /><LazyLoadWrapper><Keep isHistoryPage={true} cookieName={COOKIES.visited}/></LazyLoadWrapper></>} />
                <Route path="applyList" element={<><HeadWithOgp noindex={true} pretitle={i18next.t("head.pretitle.applylist")} /><LazyLoadWrapper><Keep isApplyPage={true} cookieName={COOKIES.applyed}/></LazyLoadWrapper></>} />
                <Route path="callList" element={<><HeadWithOgp noindex={true} pretitle={i18next.t("head.pretitle.calllist")} /><LazyLoadWrapper><Keep isCallPage={true} cookieName={COOKIES.called}/></LazyLoadWrapper></>} />

                <Route path="sitemap" element={<><HeadWithOgp pretitle={i18next.t("head.pretitle.sitemap")} /><LazyLoadWrapper><SitemapTop /></LazyLoadWrapper></>} />
                <Route path="sitemap/:area" element={<><HeadWithOgp pretitle={i18next.t("head.pretitle.sitemap")} /><LazyLoadWrapper><SitemapAreaTop /></LazyLoadWrapper></>} />

                {/* <Route path="ad" element={<><Header /><Ad /><Footer /></>} />
                <Route path="shop" element={<MarketingShop />} /> */}
                <Route path="*" element={<><Head /><LazyLoadWrapper><NotFound/></LazyLoadWrapper></>} />
              </Route>
            </Routes>
          </Router>
        </LanguageCheck>
      </CookiesProvider>
    </HelmetProvider>
  </>
);
