import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { getRequest } from './common/request.js';

import FormError from "./formerror.js"
import FormPage2 from "./form_page2.js"
import FormPage3 from "./form_page3.js"
import FormPage1 from "./form_page1.js"


export default function EditForm() {
  // const { register, watch, getValues, FormProvider, handleSubmit, formState: {errors} } = useForm({mode: "onTouched", shouldFocusError: false});
  // const watchArea = watch("areaId", "");
  const methods = useForm({mode: "onTouched", shouldFocusError: false})
  const [offer, setOffer] = useState()
  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);

  let { jobId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    fetch(getRequest("/temporary/"+jobId+"/edit"))
    .then(res => res.json())
    .then(json => {
      console.log(json)
      setOffer(json)

      const storeImages = json.Store.StoreImages
      const newImages = storeImages.map((target) => {
        target["preview"] = target["url"];
        target["registered"] = true
        return target;
      })
      setFiles(newImages)

      if(json.Store.Employees[0] && json.Store.Employees[0].imageUrl != "" && json.Store.Employees[0].imageUrl != undefined) {
        setPhotos([{id: json.Store.Employees[0].id, preview: json.Store.Employees[0].imageUrl, registered: true}]);
      }
    });
  }, [])

  const onSubmit = data => {
    let formData = new FormData();
    let deleteJson = {files:[], photos:[]};

    for(let i = 0; i < files.length; i++){
      //deleteフラグが立っているもの、削除のjsonを作ってIDを渡す
      //deleteのフラグが立っていないもの、registeredのフラグがたっていないものは新規だからappendする
      //deleteのフラグが立ち、registeredもたつものは既存で変更なしだから送らない
      if(files[i].delete){
        deleteJson.files.push(files[i].id);
      }
      else if(!files[i].delete && !files[i].registered){
        formData.append("files", files[i].raw);
      }

    }
    delete data.files

    for(let i = 0; i < photos.length; i++){
      if(photos[i].delete){
        deleteJson.photos.push(photos[i].id);
      }
      else if(!photos[i].delete && !photos[i].registered){
        console.log("add photo")
        formData.append("photo", photos[i].raw)
        delete data.photo
      }
    }

    data.id = jobId;
    data.deleteImages = deleteJson;

    formData.append('json', JSON.stringify(data));
      const options = {
        method: 'PATCH',
        body: formData
      };

      fetch(getRequest('/temporary'), options).then(function(response) {
      if (response.status == 200) {
        response.json()
        .then((result) => {
          navigate('/completeEdit', {state: {id: result.id}})
        })
      }
      else {
        // TODO: 更新に失敗しました
      }
    });
  }

  return (
    <main className="flex-1 overflow-x-hidden flex-grow">
      <section className="bg-gray-100 p-6 py-8">
      <div className="mb-4">
        <h2 className="text-center font-bold md:text-xl text-lg">{t("editjob.title")}</h2>
      </div>
      <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormPage1 offer={offer} setImages={setFiles} images={files}/>
        <FormPage2 offer={offer} />
        <FormPage3 offer={offer} setImages={setPhotos} images={photos}/>
        <div className="mb-8 text-center">
          <input type="submit" className="bg-red-500 rounded-sm px-6 py-2 text-lg text-white w-full max-w-[240px]"/>
        </div>
      </form>
      </FormProvider>
      </section>
    </main>
  );
}
