import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRequest } from './common/request';

const JobListHeader = React.memo(() => {
  const { t } = useTranslation();

  return (
    <thead className="bg-gray-100 dark:bg-gray-700">
        <tr>
            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                {t("admin.joblist.storename")}
            </th>
            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                {t("admin.joblist.jobtitle")}
            </th>
            <th scope="col" className="p-4">
                <span className="sr-only">{t("admin.joblist.edit")}</span>
            </th>
        </tr>
    </thead>
  )
})

const JobListRow = React.memo((props) => {
  const { t } = useTranslation();

  return(
    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
      {props.list && props.list.map((job, index) => {
        return (
          <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">{job.Store.name}</td>
              <td className="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">{job.Occupation.title}</td>
              <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                  <Link to={"/jobs/" + job.id } className="text-blue-600 dark:text-blue-500 hover:underline">{t("admin.joblist.row.view")}</Link>
                  <br />
                  <Link to={"/jobs/" + job.id + "/edit" } className="text-blue-600 dark:text-blue-500 hover:underline">{t("admin.joblist.row.edit")}</Link>
              </td>
          </tr>
        )
      })}
    </tbody>
  )
})

const JobList = React.memo(() => {
  const [jobList, setJobList] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    console.log("run")
    fetch(getRequest("/temporary"))
    .then(res => res.json())
    .then(json => {
        setJobList(json)
    });
  }, [])

  return (
    <main className="flex-1 overflow-x-hidden flex-grow">
      <section className="bg-gray-100 p-6 py-8">
      <div className="mb-4 text-right">
        <Link to="/form" name="newButton" className="bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">{t("admin.joblist.new")}</Link>
      </div>
      <div className="mb-4">
        <h2 className="text-center font-bold md:text-xl text-lg">{t("admin.joblist.list")}</h2>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
              <JobListHeader/>
              <JobListRow list={jobList} />
              </table>
            </div>
          </div>
        </div>
      </div>
      </section>
    </main>
  )
})

export {JobList}
