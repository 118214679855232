import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SNSAccount from '../multiPage/snsAccount';

function Footer() {
    const { t } = useTranslation();
    const [breakElements, setBreakElements] = useState([]);
    const ulRef = useRef(null);

    useEffect(() => {
        const detectBreaks = () => {
        if (ulRef.current) {
            const items = Array.from(ulRef.current.children);
            let currentTop = items[0].getBoundingClientRect().top;
            let breaks = [0];

            for (let i = 1; i < items.length; i++) {
            const itemTop = items[i].getBoundingClientRect().top;
            if (itemTop > currentTop) {
                breaks.push(i);
                currentTop = itemTop;
            }
            }

            setBreakElements(breaks);
        }
        };

        detectBreaks();
    }, []);

    return (
        <footer className="py-6 text-center md:py-8 bg-gray-900 text-white">
            <SNSAccount/>
        <ul className="text-xs flex flex-wrap items-center mb-4 justify-center gap-y-2" ref={ulRef}>
            {['privacypolicy', 'terms', 'contact', 'sitemap'].map((link, index) => (
            <li key={index} className={`px-2 border-gray-500 ${breakElements.includes(index)? 'border-l-0': 'border-l'}`}
            >
                <Link className="hover:opacity-50 duration-200" to={`/${link}`}>
                {t(`footer.${link}`)}
                </Link>
            </li>
            ))}
        </ul>
        <p className="text-center font-roboto text-lg">{t('footer.mangojob')}</p><small className="text-xs text-gray-200">{t('footer.inc')}</small>
        </footer>
    );
}

export default Footer