import React, { useState, useEffect } from 'react';
import { useForm, useFormContext, useFieldArray } from "react-hook-form";
import FormError from "./formerror.js"
import {TextInput, FileInput, SelectInput, TextArea} from "./formComponent.js"
import { useTranslation } from 'react-i18next';
import CircleRadiusBackground from './circle_radius_background.js';
import { LazyImage } from './components/commonComponent.js';
import { getRequest } from './common/request.js';

function QAArea(props) {
  const { register, watch, getValues, handleSubmit, formState: {errors}, setValue } = useFormContext();
  const { t } = useTranslation();

  const watchP = watch(`qas.${props.index}.question`, "");

  const selectRule = {
    required: t("girlsquestion.errormessage.select")
  }
  const requireRule = {
    required: t("girlsquestion.errormessage.input")
  }

  useEffect(() => {
    if(props.answer){
      setValue(`qas.${props.index}.answer`, props.answer);
    }
    if(props.question){
      setValue(`qas.${props.index}.question`, props.question);
    }
  }, [props.answer, props.question])

  useEffect(() => {
    if(props.offer){
      setValue("girl_name", props.offer.Store.Employees[0].name);
      setValue("occupationId", props.offer.Store.Employees[0].occupationId);
      setValue("from", props.offer.Store.Employees[0].from);
      setValue("comment", props.offer.Store.Employees[0].comment);
    }

  }, [props.offer])

  const onClick = (event) => {
    props.onRemoveComponent(props.index);
    event.stopPropagation();
    event.preventDefault();
  }

  const getIndex = (v) => {
    const reg = new RegExp(v);
    let index = -1

    console.log(typeof v)

    if(v == undefined || v == ""){
      return index;
    }

    for(let i = 0; i < props.questions.length; i++){
      if(props.questions[i].text.match(reg)){
        index = i;
        break;
      }
    }

console.log(index);
    return index;
  }

  return (
    <div className="flex">
    <div className="w-4/5">
    <select {...register(`qas.${props.index}.question`, selectRule)} className="outline-0 form-select border border-gray-300 rounded-sm text-lg px-2 py-1 w-full">
    <option value=''>{t("girlsquestion.placeholder.question")}</option>
      {[...props.questions, {text: props.registerQuestion, id: -1}].map((value) => {
        return value.text && <option value={value.text} key={1000 + value.id}>{value.text}</option>
      }) }
    </select>
    { (watchP != undefined && watchP != "") && <input {...register(`qas.${props.index}.answer`, requireRule)} placeholder={props.questions[getIndex(watchP)] && props.questions[getIndex(watchP)].placeholder} className="outline-0 form-input border border-gray-300 rounded-sm text-lg px-2 py-1 w-full placeholder-gray-500 placeholder-opacity-50" />}
    </div>
      <div className="w-1/5">
      { props.index != 0 && (
        <button onClick={onClick}>
          <LazyImage src={`${process.env.PUBLIC_URL}/img/remove.svg`} />
        </button>
      )
      }
      </div>
    </div>
  )
}


export default function FormPage3(props) {
  const { setValue } = useFormContext();

  const [occupations, setOccupations] = useState([])

  const { t } = useTranslation();

  useEffect(() => {
    fetch(getRequest("/occupations"))
    .then(res => res.json())
    .then(json => {
        setOccupations(json)
    });
  }, [])

  useEffect(() => {
    if(props.offer && props.offer.Store.Employees.length > 0){
      setValue("girl_name", props.offer.Store.Employees[0].name);
      setValue("occupationId", props.offer.Store.Employees[0].occupationId);
      setValue("from", props.offer.Store.Employees[0].from);
      setValue("comment", props.offer.Store.Employees[0].comment);
    }
  }, [props.offer])

    return (
      <section className="mb-8">

        <CircleRadiusBackground>
          <div className="mb-6">
            <h2 className="font-bold mb-1 text-lg">{t("girls.title")}</h2>

            <div className="mb-4 text-sm">
                <p>{t("girls.subtitle")}</p>
            </div>
          </div>

          <TextInput title={t("girls.label.name")} registerName="girl_name" placeholder={t("girls.placeholder.name")} focus={props.focus} blur={props.blur}/>
          <FileInput title={t("girls.label.photo")} registerName="photo" setImages={props.setImages} images={props.images} keyStart={100} subtitle={t("girls.sublabel.photo")}/>
          <SelectInput title={t("girls.label.occupation")} registerName="occupationId" placeholder={t("girls.placeholder.occupation")} selects={occupations} target="title" focus={props.focus} blur={props.blur} defaultValue={props.storedData? props.storedData.occupationId: ""}/>
          <TextInput title={t("girls.label.from")} registerName="from" placeholder={t("girls.placeholder.from")} focus={props.focus} blur={props.blur} />
          <TextArea title={t("girls.label.comment")} registerName="comment" placeholder={t("girls.placeholder.comment")} subtitle={t("girls.sublabel.comment")} focus={props.focus} blur={props.blur} />
        </CircleRadiusBackground>

      </section>
    )
}
