import i18next from 'i18next';

const FormRule = {
  stringRules: {
    required: i18next.t("formRule.errorMessage.required"),
    maxLength: { value: 32, message: i18next.t("formRule.errorMessage.string.maxlength") },
    pattern: { value: /^[a-zA-Z0-9]+$/, message: i18next.t("formRule.errorMessage.string.pattern") }
  },
  requireRule: {
    required: i18next.t("formRule.errorMessage.required")
  },
  selectRule: {
    required: i18next.t("formRule.errorMessage.required")
  },
  textRules: {
    required: i18next.t("formRule.errorMessage.required"),
    maxLength: { value: 500, message: i18next.t("formRule.errorMessage.text.maxlength") }
  }
}

export {FormRule}
// const stringRules = {
//   required: '入力してください',
//   maxLength: { value: 32, message: '32文字以内で入力してください。' },
//   pattern: { value: /^[a-zA-Z0-9]+$/, message: '半角英数字で入力してください。' }
// }
// const requireRule = {
//   required: '入力してください'
// }
// const selectRule = {
//   required: '選択してください'
// }
// const textRules = {
//   required: '入力してください',
//   maxLength: { value: 500, message: '500文字以内で入力してください。' }
// }
