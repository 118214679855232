import React, { useState, useEffect } from 'react';

const Overlay = React.memo((props) => {
    const [isShow, setShow] = useState(false)
    const tapClose = (e) => {
        setShow(false)
        e.stopPropagation()
        e.preventDefault()
    }
    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 1500)
    }, []);

    return (
        <>
            <div className={"overflow-x-hidden overflow-y-auto fixed h-modal h-full w-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center "+ (isShow? "flex": "hidden")}>
                <div className="relative w-full h-full bg-gray-700/50" onClick={tapClose}>
                    <div className="bg-white rounded-lg shadow relative m-4 p-2" onClick={stopPropagation}>
                        <div className="flex justify-end p-2">
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={tapClose}>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                            </button>
                        </div>
                        <div>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export {Overlay}