import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 言語jsonファイルのimport
import translation_en from "./en.json";
import translation_th from "./th.json";

const resources = {
    en: {
        translation: translation_en
    },
    th: {
        translation: translation_th
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "th",
        debug: false,
        interpolation: {
            escapeValue: false,
        }
    });

i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
});

export default i18n;
