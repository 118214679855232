import React, { useState, useEffect, forwardRef } from 'react';
import { useForm, useFormContext } from "react-hook-form";
import {FormError} from "./formerror.js"
import {FormRule} from "./formRule.js"
import { useTranslation } from 'react-i18next';
import { LazyImage } from './components/commonComponent.js';

const RequiredLabel = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <>
    {
      props.isRequire && (<div className="inline rounded-sm inline-flex items-center justify-center font-normal text-xs p-0.5 px-1 font-bold text-[#EE7B5E] border border-[#EE7B5E]">{t("formcomponent.required")}</div>)
    }
    </>
  )
})

const RequiredErrorMessage = React.memo((props) => {
  const { register, formState: {errors} } = useFormContext();
  return (
    <>
    {
      props.isRequire && (
      <FormError>
          {errors[props.registerName] && errors[props.registerName].message}
      </FormError>
    )
    }
    </>
  )
})



const BaseInput = React.memo((props) => {
  return(
    <div className={props.isEnd? "": "mb-5"}>
      <h3 className="font-bold mb-1 flex space-x-2">
        <span>{props.title}</span>
        <RequiredLabel isRequire={props.isRequire}/>
      </h3>
      <div className="text-gray-500 text-sm mb-1">{props.subtitle}</div>
      {props.children}
      <RequiredErrorMessage isRequire={props.isRequire} registerName={props.registerName} />
    </div>
  )
})

const HiddenInput = React.memo((props) => {
  const { register } = useFormContext();

  return(
    <input {...register(props.registerName)} type="hidden" value={props.value} />
  )
})

const TextInput = React.memo((props) => {
  const { register } = useFormContext();

  return (
    <BaseInput isRequire={props.isRequire} title={props.title} registerName={props.registerName} subtitle={props.subtitle} isEnd={props.isEnd}>
      <input {...register(props.registerName, (props.isRequire? FormRule.requireRule: {}))} placeholder={props.placeholder} className="outline-0 form-input border border-gray-300 rounded-sm text-lg px-2 py-1 w-full placeholder-gray-500 placeholder-opacity-50 rounded-lg focus:ring focus:ring-corporaitopacity" onFocus={props.focus} onBlur={props.blur} />
    </BaseInput>
  )
});

const getUnique = () => {
  const random = Math.random().toString(32).substring(2);

  return random
}

const FileInput = React.memo(forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { register, watch } = useFormContext();
  // const [props.images, setImages] = useState([])

  const watchFiles = watch(props.registerName, "");

  // fileのプレビューのため
  useEffect(() => {
    if(watchFiles && watchFiles.length > 0){
      for(var i = 0; i < watchFiles.length; i++){
        let fileReader = new FileReader();
        ((target) => {
          fileReader.onload = (e) => {
            const {result} = e.target;
            if(result){
              props.setImages((prev) => {
                console.log(target)
                return [...prev, {preview: result, raw: target, id: getUnique()}]
              })
            }
          }
          fileReader.readAsDataURL(target);
        })(watchFiles[i])

      }
    }

  }, [watchFiles])

  const onClick = (index) => {
    props.setImages((prev) => {
      for(let i = 0, length = prev.length; i < length; i++){
        if(prev[i].id == index){
          // DBに登録済みの画像であれば配列から削除するのではなく削除フラグを立てるだけにする。
          if(prev[i].registered){
            prev[i].delete = true
          }
          else{
            prev.splice(i, 1);
          }
          break;
        }
      }

      return [...prev]
    })
  }

  const fileRequiredRule = {validate: value => props.images.length > 0 || t("formcomponent.errormessage.selectfile") }

  return (
    <>
      <BaseInput isRequire={props.isRequire} title={props.title} registerName={props.registerName} subtitle={props.subtitle} isEnd={props.isEnd}>
        <label className="bg-red-500 text-white text-xs py-1 px-2">{t("formcomponent.filebutton")}
          <input type="file" {...register(props.registerName, (props.isRequire? fileRequiredRule: {}))} multiple={props.multiple? true: false} className="hidden"/>
        </label>
      </BaseInput>
      {
        props.images.length > 0 && props.images.map((img, index) =>
          !img.delete && (
            <div key={props.keyStart ? props.keyStart + img.id: img.id} className="relative w-1/4">
              <LazyImage className="" src={img.preview}/>
              <div className="rounded-full bg-slate-900/50 w-7 h-7 absolute top-1 right-1">
              <button onClick={() => {onClick(img.id)}}>
                <LazyImage src={`${process.env.PUBLIC_URL}/client/close.svg`} className="w-7"/>
              </button>
              </div>
            </div>
          )
        )
      }
    </>
  )
}));

const SelectInput = React.memo((props) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    if(props.defaultValue && props.defaultValue != ""){
      // setTimeout(function(){
      setValue(props.registerName, props.defaultValue)
    // }, 100);
    }
  }, [props.selects])

  return (
    <BaseInput isRequire={props.isRequire} title={props.title} registerName={props.registerName} subtitle={props.subtitle} isEnd={props.isEnd}>
      <select {...register(props.registerName, (props.isRequire? FormRule.selectRule: {}))} className="disabled:bg-slate-50 disabled:text-slate-500 outline-0 form-select border border-gray-300 rounded-sm text-lg px-2 py-1 w-full rounded-lg focus:ring focus:ring-corporaitopacity" onFocus={props.focus} onBlur={props.blur}>
        <option value=''>{props.prePlaceholder && (props.selects.length <= 0)? props.prePlaceholder: props.placeholder}</option>
        {props.selects.map((value) => {
            return <option value={value.id} key={value.id}>{props.target ? value[props.target]: value.name}</option>
        })}
      </select>
    </BaseInput>
  )
})

const TextArea = React.memo((props) => {
  const { register } = useFormContext();
  return (
    <BaseInput isRequire={props.isRequire} title={props.title} registerName={props.registerName} subtitle={props.subtitle} isEnd={props.isEnd}>
      <textarea {...register(props.registerName, (props.isRequire? FormRule.requireRule: {}))} placeholder={props.placeholder} className="outline-0 form-textarea h-32 border border-gray-300 rounded-sm text-lg px-2 py-1 w-full placeholder-gray-500 placeholder-opacity-50 rounded-lg focus:ring focus:ring-corporaitopacity" onFocus={props.focus} onBlur={props.blur}></textarea>
    </BaseInput>
  )
})

const DateInput = React.memo((props) => {
  const { register } = useFormContext();
  return (
    <BaseInput isRequire={props.isRequire} title={props.title} registerName={props.registerName} subtitle={props.subtitle} isEnd={props.isEnd}>
      <input type="date" {...register(props.registerName, (props.isRequire? FormRule.selectRule: {}))} />
    </BaseInput>
  )
})

export {TextInput, FileInput, SelectInput, TextArea, DateInput, HiddenInput}
