import React, { useState, useEffect } from 'react';
import { useForm, useFormContext } from "react-hook-form";
import {TextInput, FileInput, SelectInput, TextArea} from "./formComponent.js"
import { useTranslation } from 'react-i18next';
import CircleRadiusBackground from './circle_radius_background.js';
import { getRequest } from './common/request.js';

const FormPage1 = React.memo((props) => {
  const { watch, getValues, setValue } = useFormContext();

  const watchArea = watch("areaId", "");
  const [areas, setAreas] = useState([])
  const [subareas, setSubAreas] = useState([])
  const [types, setTypes] = useState([])
  const [store, setStore] = useState([])

  const { t } = useTranslation();

  useEffect(() => {
    fetch(getRequest("/areas/"))
    .then(res => res.json())
    .then(json => {
        setAreas(json)
    });

    fetch(getRequest("/storetypes/"))
    .then(res => res.json())
    .then(json => {
      setTypes(json)
    });
  }, [])

  useEffect(() => {
    if(props.offer){
      setTimeout(function(){
        setValue("name", props.offer.Store.name);
        setValue("areaDetail", props.offer.Store.areaDetail);
        setValue("typeId", props.offer.Store.StoreType.id);
        setValue("subAreaId", props.offer.Store.subAreaId);
        setValue("areaId", props.offer.Store.areaId);
      }, 1000)

    }
  }, [props.offer])

  React.useEffect(() => {
    fetch(getRequest("/subareas", {areaid: getValues("areaId")}))
    .then(res => res.json())
    .then(json => {
      setSubAreas(json);
    })
  }, [watchArea]);

  return (
    <section className="mb-8">
      <CircleRadiusBackground>
        <TextInput title={t("jobform.label.storename")} registerName="name" placeholder={t("jobform.placeholder.storename")} isRequire={true} focus={props.focus} blur={props.blur}/>

        <SelectInput title={t("jobform.label.business")}  registerName="typeId" isRequire={true} placeholder={t("jobform.placeholder.business")}  selects={types} focus={props.focus} blur={props.blur} defaultValue={props.storedData? props.storedData.typeId: ""} />

        <FileInput title={t("jobform.label.storeimage")}  registerName="files" multiple={true} setImages={props.setImages} images={props.images} subtitle={t("jobform.subtitle.storeimage")} isEnd={true} />
      </CircleRadiusBackground>

      <CircleRadiusBackground>
        <SelectInput title={t("jobform.label.area")}  registerName="areaId" isRequire={true} placeholder={t("jobform.placeholder.area")} selects={areas} subtitle={t("jobform.subtitle.area")} focus={props.focus} blur={props.blur} defaultValue={props.storedData? props.storedData.areaId: ""}/>

        <SelectInput title={t("jobform.label.subarea")}  registerName="subAreaId" defaultValue={props.offer? props.offer.Store.subAreaId: (props.storedData? props.storedData.areaId: "")} isRequire={true} placeholder={t("jobform.placeholder.subarea")} prePlaceholder={t("jobform.preplaceholder.subarea")} selects={subareas} focus={props.focus} blur={props.blur} />

        <TextArea title={t("jobform.label.areadetail")} registerName="areaDetail" placeholder={t("jobform.placeholder.areadetail")} subtitle={t("jobform.subtitle.areadetail")} isEnd={true} focus={props.focus} blur={props.blur} />
      </CircleRadiusBackground>

    </section>
  )
})

export default FormPage1
